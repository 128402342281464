body {
    margin: 0;
    font-family: "Segoe UI";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Segoe UI";
}

.footer-text {
    position: fixed;
    bottom: 3px;
    width: 100%;
    text-align: center;
    font-size: 10px;
    z-index: 1000;
    font-weight: bold;
    font-family: "Segoe UI";
}