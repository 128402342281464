.form-container {
    max-height: 500px; 
    overflow:auto;
    width: 100% !important;
    padding-top: 7px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
}
.form-container::-webkit-scrollbar {
    width: 8px; 
    height: 8px; 
}

.form-container::-webkit-scrollbar-track {
    background: transparent;
}


.form-container::-webkit-scrollbar-thumb {
    background: rgba(209, 209, 209, 0.5); 
    border-radius: 10px; 
}

.form-container::-webkit-scrollbar-thumb:hover {
    background: rgba(119, 119, 119, 0.8); 
}

.form-container {
    -ms-overflow-style: none; 
}
.form-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.form-grid label {
    flex: 0 0 calc(50% - 16px); 
    display: flex;
    flex-direction: column;
}
.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 16px; 
}

.input-container {
    display: flex;
    align-items: center;
    gap: 8px; 
}
